import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { Row, Col, Card as CardWrapper, CardBody } from 'reactstrap';

import Card from './Card';

import chartLocales from '../utils/locales';
import { convertCents, formatCurrency } from '../../../helpers/format';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import useQsParams from '../../../Components/Hooks/QueryString';
import {
  getCashbackCardsData,
  getCashbackChartData,
} from '../../../slices/manager-panel/thunk';
import { getFilters } from '../../../Components/Common/Filter';
import { isEmpty } from 'lodash';

const Cashback = () => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();
  const [, setFiltersParams] = useState({});

  const buildUrlWithFilters = (baseUrl) => {
    const url = new URL(baseUrl, window.location.origin);
    const appliedFilters = [
      { field: 'created_at', value: searchParams.get('created_at') },
      { field: 'channel', value: searchParams.get('channel') },
      { field: 'store_id', value: searchParams.get('store_id') },
    ];

    appliedFilters.forEach(({ field, value }) => {
      if (value) {
        url.searchParams.append(field, value);
      }
    });

    return url.toString();
  };

  const filtersAplicated = {
    cashbackGenerated: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done&generated_cashback=001%2C100000000'),
    cashbackRedeemed: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done&used_cashback=001%2C100000000'),
    cashbackExpirated: buildUrlWithFilters('/vendas?page=1&status=expired'),
    cashbackAvailable: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,done&generated_cashback=001%2C100000000'),
  };

  const { cashback } = useSelector(
    createSelector(
      (state) => state.ManagerPanel,
      ({ cashback }) => ({ cashback })
    )
  );

  useEffect(() => {
    getData();
  }, [dispatch, searchParams.toString()]);

  const filters = [
    { field: 'created_at' },
    { field: 'channel' },
    { field: 'store_id' },
  ];

  const getData = () => {
    const params = {};
    sessionStorage.removeItem('filterByStores');
    const appliedFilters = getFilters(filters, [
      { field: 'created_at', type: 'between_date' },
      { field: 'channel', type: 'in' },
      { field: 'store_id', type: 'in' },
    ]);

    const storesIds = appliedFilters?.store_id;
    if (storesIds) {
      sessionStorage.setItem('filterByStores', storesIds.in[0]);
    }

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    setFiltersParams(params.filters);
    dispatch(getCashbackCardsData(params));
    dispatch(getCashbackChartData(params));
  };

  return (
    <div>
      <h2 className="mb-3">Cashback</h2>

      <Row>
        <Col xs={12} md={6} xl={3}>
          {cashback?.cards?.generated?.length ? (
            <Card
              title="Cashback gerado"
              link={filtersAplicated.cashbackGenerated}
              data={cashback?.cards?.generated}
            />
          ) : (
            <div className="skeleton card" style={{ height: '101px' }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {cashback?.cards?.redeemed?.length ? (
            <Card
              title="Cashback Resgatado"
              link={filtersAplicated.cashbackRedeemed}
              data={cashback?.cards?.redeemed}
            />
          ) : (
            <div className="skeleton card" style={{ height: '101px' }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {cashback?.cards?.expired?.length ? (
            <Card
              title="Cashback Expirado"
              link={filtersAplicated.cashbackExpirated}
              data={cashback?.cards?.expired}
            />
          ) : (
            <div className="skeleton card" style={{ height: '101px' }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {cashback?.cards?.available?.length ? (
            <Card
              title="Cashback Disponível"
              link={filtersAplicated.cashbackAvailable}
              data={cashback?.cards?.available}
            />
          ) : (
            <div className="skeleton card" style={{ height: '101px' }} />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <CardWrapper>
            <CardBody>
              {cashback?.chart?.data?.length ? (
                <ReactApexChart
                  type="bar"
                  height={350}
                  options={{
                    chart: {
                      stacked: false,
                      toolbar: { show: false },
                      ...chartLocales,
                    },
                    plotOptions: {
                      bar: { horizontal: false, columnWidth: '60%' },
                    },
                    dataLabels: { enabled: false },
                    legend: {
                      onItemHover: { highlightDataSeries: true },
                      onItemClick: { toggleDataSeries: true },
                    },
                    colors: ['#AAFF00', '#ff6400', '#0e93c2', '#ec616c'],
                    labels: cashback?.chart?.data?.map((i) => i?.date),
                    xaxis: {
                      categories: cashback?.chart?.data?.map((i) => i?.date),
                    },
                    yaxis: {
                      labels: {
                        formatter: (v) => formatCurrency(v, { convert: true }),
                      },
                    },
                  }}
                  series={[
                    {
                      name: 'Cashback gerado',
                      data: cashback?.chart?.data?.map((i) =>
                        convertCents(i?.total_available_cashback)
                      ),
                    },
                    {
                      name: 'Cashback resgatado',
                      data: cashback?.chart?.data?.map((i) =>
                        convertCents(i?.total_redeemed_cashback)
                      ),
                    },
                    {
                      name: 'Cashback expirado',
                      data: cashback?.chart?.data?.map((i) =>
                        convertCents(i?.total_expired_cashback)
                      ),
                    },
                  ]}
                />
              ) : (
                <div className="skeleton card" style={{ height: 350 }} />
              )}
            </CardBody>
          </CardWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(Cashback);
