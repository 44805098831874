import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { Label } from 'reactstrap';
import React, { useEffect } from 'react';

const Dropdown = ({
  t,
  label,
  description,
  options,
  values = [],
  isMulti,
  disabled,
  onChange,
}) => {
  const formatGroupLabel = (data) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span className="text-black fw-semibold">{data.label}</span>
      <span
        style={{
          display: 'inline-block',
          padding: '0.16666666666667em 0.5em',
          backgroundColor: '#49c997',
          borderRadius: '2em',
          textAlign: 'center',
          fontWeight: 'normal',
          lineHeight: '1',
          color: 'white',
          fontSize: 12,
          minWidth: 1,
        }}
      >
        {data.options.length}
      </span>
    </div>
  );

  return (
    <div style={{ position: 'relative', zIndex: 2 }}>
      {label && (
        <Label className="form-label fs-5 fw-semibold text-muted m-0">
          {label}
        </Label>
      )}

      {description && (
        <Label className="form-label fs-6 d-block text-muted">
          {description}
        </Label>
      )}
      {values &&
        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              ':hover': { borderColor: '#e9ebec' },
              borderColor: '#e9ebec',
              boxShadow: 'none',
            }),

            multiValueLabel: () => ({
              borderRadius: '5px 0 0 5px',
              background: '#49c997',
              fontWeight: '600',
              color: 'white',
              padding: 8,
            }),

            multiValueRemove: (baseStyles) => ({
              ...baseStyles,
              borderRadius: '0 5px 5px 0',
              background: '#48bb8e',
              color: 'white',
              fontSize: 20,
              padding: 10,
            }),

            multiValue: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 5,
            }),

            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: 8,
              gap: 10,
            }),
          }}
          onChange={onChange}
          placeholder={t('dropdown.placeholder')}
          noOptionsMessage={() => t('dropdown.noOptionsMessage')}
          formatGroupLabel={formatGroupLabel}
          defaultValue={Array.isArray(values) ? values : []}
          options={Array.isArray(options) ? options : []}
          isDisabled={!!disabled}
          isMulti={isMulti}
          isSearchable
        />
      }
    </div>
  );
};

export default withTranslation()(Dropdown);
