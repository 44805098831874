import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { downloadHtml, formatHtml } from '../../../../helpers/html_helper';
import Editor2 from '../../../../Components/Common/Editor2';
import ActionButton from '../../../../Components/Common/ActionButton';
import useQsParams from '../../../../Components/Hooks/QueryString';
import Dropdown from '../../../../Components/Common/Dropdown';
import { sanitizeSmsBodyAndAppendSuffix } from '../helper';
import {
  getCommunicationRulesByChannel,
  getCommunicationTemplate,
  getTemplateVariables,
  updateCommunicationTemplate,
  updateCommunicationTemplateAssociatedRules
} from '../../../../helpers/bff_helper';
import { t } from 'i18next';
import { type } from '../types';
import classNames from 'classnames';

const MAX_TITLE_EMAIL = 60;
const MAX_BODY_SMS = 140;

const TemplateForm = ({ }) => {
  const { qs, setQs } = useQsParams();

  const [template, setTemplate] = useState(null);
  const [templateType, setTemplateType] = useState('');
  const [varsTemplate, setVarsTemplate] = useState('');
  const [rules, setRules] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [contentError, setContentError] = useState(false);
  const [rulesActive, setRulesActive] = useState([]);

  const id = qs.get('edit');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if (id) {
          const fetchedTemplate = await getCommunicationTemplate(id);

          setTemplate(fetchedTemplate);
          setTemplateType(fetchedTemplate?.type ?? '');
        }

      } catch (e) {
        console.error(e)
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        if (id && template) {
          const codeRulesForSearch = selectedRules.map((v) => v.value)
          
          const fetchVarsTemplate = await getTemplateVariables({
            ids: codeRulesForSearch
          });
          setVarsTemplate(fetchVarsTemplate)
        }

      } catch (e) {
        console.error(e)
      }
    };

    fetchData();
  }, [id, selectedRules]);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const fetchedRules = await getCommunicationRulesByChannel(template.type);
        const selectedRules = fetchedRules.filter((rule) => rule.template_id == id);

        const formattedRules = selectedRules.map((rule) => ({
          label: rule.event_name,
          value: rule.id,
          template_id: rule.template_id,
        }));

        const formattedRulesFormated = fetchedRules.map((rule) => ({
          label: rule.event_name,
          value: rule.id,
          template_id: rule.template_id,
        }));

        setRules(formattedRulesFormated);
        setSelectedRules(formattedRules);
        setRulesActive(formattedRules)

      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [template]);

  const formik = useFormik({
    title: '',
    subject: '',
    content: '',
    selectedRules: [],

    enableReinitialize: true,
    initialValues: {
      title: template?.title || '',
      content: template?.content || '',
      subject: template?.subject || '',
      selectedRules: selectedRules.map((v) => v.value),
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      subject: Yup.string(),
      content: templateType === 'email'
        ? Yup.string().required()
        : templateType === 'sms'
          ? Yup.string().max(MAX_BODY_SMS).required()
          : Yup.string(),
    }),
    onSubmit: async ({ selectedRules, ...values }) => {
      setDisabled(true);
      let content = values.content;

      try {

        const updateAssociatedRules = await updateCommunicationTemplateAssociatedRules(
          id,
          {
            ids: selectedRules,
          });

        if (!updateAssociatedRules) {
          toast.error(t('communication-templates.form.error'));
          return;
        }

        if (templateType === 'sms') {
          content = sanitizeSmsBodyAndAppendSuffix(content, t('communication-templates.form.sms.rules'));
        }

        await updateCommunicationTemplate(
          id,
          {
            title: values.title,
            subject: values.subject,
            content: templateType === 'whatsapp' ? undefined : content,
          });

        toast.success(t('communication-templates.form.success'));
        setQs('edit', '');
      } catch (e) {
        toast.error(t('communication-templates.form.error'));
      } finally {
        setDisabled(false);
      }
    },
  });

  useEffect(() => {
    async function fetchTemplateContent() {
      let content = template?.content || '';
      try {
        content = await formatHtml(content);
        setContentError(false);
      } catch (_) {
        setContentError(true);
      }

      formik.setFieldValue('subject', template?.subject ?? '');
      formik.setFieldValue('content', content);
    }

    if (template) {
      fetchTemplateContent();
    }
  }, [template]);

  return (
    <div className="d-flex flex-column gap-5">
      <div className="row">
        <div className="col flex-grow-1">
          {isLoading ? (
            <div className="card" style={{ height: 92 }}>
              <div className="card-body bg-light h-100 skeleton" />
            </div>
          ) : (
            <Card className="m-0">
              <CardBody>
                <div className="d-flex gap-2">
                  <h4>{t('communication-templates.form.heading')} {formik.values.title}</h4>
                </div>
                <span className="mt-2 text-muted">
                  {t('communication-templates.form.email.description')}
                </span>
              </CardBody>
            </Card>
          )}
        </div>

        <div className="col-lg-auto col-12 mt-lg-0 mt-3">
          {isLoading ? (
            <div className="card" style={{ height: 92, width: 400 }}>
              <div className="card-body w-100 bg-light h-100 skeleton" />
            </div>
          ) : (
            <Card className="m-0 h-100">
              <CardBody className="d-flex flex-column flex-md-row align-items-sm-center gap-3">
                <input
                  hidden
                  id="html-upload"
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();

                    reader.onload = async (e) => {
                      const raw = e.target.result;
                      const formatted = await formatHtml(raw);
                      formik.setFieldValue('content', formatted);
                    };

                    if (reader && file) reader.readAsText(file);
                  }}
                />
                {templateType === 'email' && (
                  <>
                    <ActionButton
                      style="primary"
                      label={t('communication-templates.form.email.upload')}
                      icon="bx-upload"
                      onClick={() => document.getElementById('html-upload')?.click()}
                      disabled={disabled}
                    />
                    <ActionButton
                      style="primary"
                      label={t('communication-templates.form.email.download')}
                      icon="bx-download"
                      onClick={() => downloadHtml(formik.values.content, `${formik.values.title}.html`)}
                      disabled={disabled}
                    />
                  </>
                )}
                <ActionButton
                  label={t('communication-templates.form.save')}
                  icon="bx-check"
                  onClick={formik.submitForm}
                  disabled={!formik.isValid || disabled}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>

      <div>
        {rules.length ? (
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody style={{ zIndex: 2 }}>
                  <Dropdown
                    isMulti
                    disabled={isLoading}
                    label={t('communication-templates.form.rules.label')}
                    description={t('communication-templates.form.rules.description')}
                    values={selectedRules}
                    options={rules}
                    onChange={(selectedValues) => {
                      const valuesArr = selectedValues.reduce(
                        (acc, curr) => [...acc, curr.value],
                        []
                      );
                      setSelectedRules(selectedValues);
                      setRulesActive(valuesArr)
                      formik.setFieldValue('selectedRules', valuesArr);
                    }}

                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <div className="card" style={{ height: 150 }}>
            <div className="w-100 card-body bg-light h-100 skeleton" />
          </div>
        )}
      </div>

      <Row>
        <Col sm={12}>
          {isLoading ? (
            <div className="card" style={{ height: 800 }}>
              <div className="w-100 card-body bg-light h-100 skeleton" />
            </div>
          ) : (
            <Card>
              <CardBody>
                {templateType === 'email' && (
                  <div className="mb-4">
                    <Editor2
                      id="subject"
                      label={t('communication-templates.form.email.subject')}
                      placeholder={t(
                        'communication-templates.form.email.subject-placeholder'
                      )}
                      onChange={(v) => formik.setFieldValue('subject', v)}
                      invalid={!!formik.errors.subject}
                      value={formik.values.subject}
                      hasRules={rulesActive}
                      variables={varsTemplate}
                      config={{
                        type: templateType,
                        formatOnPaste: false,
                        lineNumbers: false,
                        highlightActiveLine: false,
                        maxCharacters: MAX_TITLE_EMAIL,
                      }}
                    />
                  </div>
                )}


                <Editor2
                  id="content"
                  label={t(
                    templateType === 'email'
                      ? 'communication-templates.form.email.content'
                      : 'communication-templates.form.sms.content'
                  )}
                  error={
                    contentError
                      ? t('communication-templates.form.email.error')
                      : null
                  }
                  placeholder={t(
                    templateType === 'email'
                      ? 'communication-templates.form.email.content-placeholder'
                      : 'communication-templates.form.sms.content-placeholder'
                  )}
                  onChange={(v) => {
                    const content = templateType === 'sms' ? _.deburr(v) : v;
                    formik.setFieldValue('content', content);
                  }}
                  invalid={!!formik.errors.content}
                  value={formik.values.content}
                  hasRules={rulesActive}
                  variables={varsTemplate}
                  config={{
                    type: templateType,
                    height: templateType === 'email' ? 545 : 200,
                    formatOnPaste: templateType === 'email',
                    lineNumbers: templateType === 'email',
                    highlightActiveLine: templateType === 'email',
                    maxCharacters: templateType === 'sms' || templateType === 'whatsapp' ? MAX_BODY_SMS : 0,
                    lineWrap: templateType === 'sms' || templateType === 'whatsapp',
                    disabled: templateType === 'whatsapp',
                  }}
                />
              </CardBody>
            </Card>
          )}
        </Col>

        <Col
          sm={12}
          md={templateType === 'sms' || templateType === 'whatsapp' ? 8 : 12}
          className={templateType === 'sms' || templateType === 'whatsapp' ? 'px-3' : 'px-5'}
        >
          {isLoading ? (
            <div className="card" style={{ height: 800 }}>
              <div className="w-100 card-body bg-light h-100 skeleton" />
            </div>
          ) : (
            <div
              style={{
                border: '15px solid black',
                borderRadius: 30,
                width: '100%',
              }}
            >
              <div
                style={{ borderRadius: '15px 15px 0 0' }}
                className="p-3 fs-5 bg-light"
              >
                <div
                  className={classNames({
                    'd-flex row': true,
                    'justify-content-between': templateType === 'email',
                  })}
                >
                  {templateType === 'email' ? (
                    <div className="col">
                      <p className="m-0">
                        {t('communication-templates.form.email.subject')}
                      </p>
                      <span className="fw-semibold">
                        {formik.values.subject || '-'}
                      </span>
                    </div>
                  ) : null}

                  <div
                    className={classNames({
                      'd-flex justify-content-end align-items-center': true,
                      'col-auto': templateType === 'email',
                    })}
                  >
                    <div className="border border-2 border-success py-2 px-4 rounded-pill shadow-lg">
                      <span className="fs-5 fw-semibold">
                        {type[templateType] ?? '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="w-100 bg-white overflow-x-auto"
                style={{
                  borderRadius: '0 0 15px 15px',
                  height: templateType === 'email' ? 700 : 250,
                  padding: templateType === 'sms' || templateType === 'whatsapp' ? 10 : 0,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      templateType === 'email' || templateType === 'whatsapp'
                        ? formik.values.content
                        : sanitizeSmsBodyAndAppendSuffix(
                          formik.values.content,
                          t('communication-templates.form.sms.rules')
                        ),
                  }}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TemplateForm;
