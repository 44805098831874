import { Offcanvas } from 'reactstrap';
import useQsParams from '../Hooks/QueryString';
import React from 'react';
const Drawer = ({
  children,
  slug = 'showDrawer',
  className = 'w-75',
  setParamsOnClose = {},
  onClose = () => {},
}) => {
  const { searchParams, setSearchParamsAsObject } = useQsParams();
  const showDrawer = searchParams.get(slug) ? true : false;

  const toggleDrawer = () => {
    setSearchParamsAsObject({ [slug]: '', ...setParamsOnClose });
    if (!showDrawer && onClose) onClose();
  };

  return (
    <Offcanvas
      direction="end"
      className={`offcanvas-drawer ${className}`}
      backdropClassName="bg-primary"
      toggle={toggleDrawer}
      isOpen={showDrawer}
    >
      <button
        className="bg-success d-flex justify-content-center align-items-center border-0 close-btn"
        onClick={toggleDrawer}
      >
        <i className="bx bx-x fs-1 text-white" />
        <span className="label-btn">Fechar</span>
      </button>

      <div
        className="pt-4 pt-md-4 d-flex flex-column gap-4 h-100 overflow-y-auto"
        style={{ background: '#fafafa' }}
      >
        <div
          className="mt-5 px-4 mt-xl-0 overflow-y-auto"
          style={{ height: '100%' }}
        >
          {children}
        </div>
      </div>
    </Offcanvas>
  );
};

export default Drawer;
